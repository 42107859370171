import styled from 'styled-components';
import { Link } from 'gatsby';

export const FooterStyled = styled.div`
	width: 100%;
	background-color: #f7f8fa;
`;
export const TopWrapper = styled.div`
	@media (max-width: 740px) {
		border-bottom: 1px solid #bdc5cd;
	}
`;
export const Top = styled.div`
	border-bottom: 1px solid #bdc5cd;
	padding-top: 44px;
	display: flex;
	justify-content: space-between;
	padding-bottom: 62px;

	@media (max-width: 740px) {
		flex-direction: column;
		border-bottom: none;
	}
	@media (max-width: 425px) {
		padding-top: 34px;
		padding-bottom: 38px;
	}
`;

export const AboutUs = styled.div`
	max-width: 500px;
`;
export const AboutUsText = styled.div`
	font-weight: 300;
	font-size: 14px;
	margin-top: 19px;
	width: 400px;
	line-height: 1.5;

	& a {
		color: #000000;
		display: inline-block;
		transition: all 0.3 ease;
		&:hover {
			text-decoration: none;
		}
	}

	@media (max-width: 740px) {
		width: 100%;
	}
	@media (max-width: 425px) {
		margin-top: 12px;
	}
`;
export const Contacts = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	@media (max-width: 740px) {
		align-items: flex-start;
		margin-top: 20px;
	}
`;
export const ContactsText = styled.p`
	font-weight: 300;
	font-size: 14px;
	margin-bottom: 8px;
`;
export const ContactsTextSecond = styled.p`
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 12px;
`;
export const ContactsTextThird = styled.p`
	font-weight: 300;
	font-size: 14px;
	margin-bottom: 8px;
`;
export const ContactsTextFourth = styled.p`
	font-weight: 500;
	font-size: 14px;

	& a {
		transition: all 0.3s ease;
		cursor: pointer;
		text-decoration: none;
		color: #000000;
		&:hover {
			color: #424bec;
		}
	}
`;

export const Media = styled.div`
	margin-bottom: 26px;
	display: flex;
	justify-content: space-between;
	width: 194px;
	height: 46px;
	align-items: center;

	& a {
		color: #000000;
		cursor: pointer;
		transition: all 0.3s ease;

		&:hover {
			color: #424bec1a;
		}
	}
	@media (max-width: 425px) {
		margin-bottom: 20px;
	}
`;
export const MiddleWrapper = styled.div`
	@media (max-width: 740px) {
		border-bottom: 1px solid #bdc5cd;
	}
`;
export const Middle = styled.div`
	border-bottom: 1px solid #bdc5cd;
	padding: 40px 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media (max-width: 740px) {
		border-bottom: none;
	}
	@media (max-width: 425px) {
		padding: 26px 0 0;
	}
`;

export const BottomInner = styled.div`
	padding-top: 40px;
	display: flex;
	justify-content: space-between;
	padding-bottom: 40px;
	@media (max-width: 768px) {
		flex-direction: column;
	}
	@media (max-width: 425px) {
		padding: 33px 0 32px;
	}
`;
export const List = styled.ul`
	list-style: none;
	margin-top: 10px;
	padding: 0;
	margin-bottom: 38px;
`;
export const Item = styled.li`
	font-size: 14px;
	color: #2a3235;
	margin: 0;
	padding: 0 0 6px;
	font-weight: 300;
	&:last-child {
		padding: 0;
	}
	a {
		color: #2a3235;
		text-decoration: none;
	}
	@media (max-width: 425px) {
		width: 160px;
	}
`;

export const ListTitle = styled.span`
	font-size: 16px;
	font-weight: 700;
	color: #152231;
`;

export const NswArea = styled.div`
	display: flex;
	align-items: center;
	margin-top: 11px;
	@media (max-width: 768px) {
		margin-bottom: 26px;
	}
	@media (max-width: 425px) {
		margin-bottom: 26px;
		margin-top: 31px;
	}
`;
export const Img = styled.img`
	border-right: 1px solid #bdc5cd;
	margin-right: 8px;
	padding-right: 8px;
`;
export const Input = styled.input`
	border: 1px solid #bdc5cd;
	padding: 13px 19px;
	width: 194px;
	font-size: 18px;
	&::placeholder {
		font-size: 18px;
	}
`;

export const BottomRight = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 330px;
	& a {
		display: inline-block;
		color: #000000;
		transition: all 0.3s ease;
		& div {
			font-size: 12px;
		}

		&:hover {
			text-decoration: none;
		}
	}
`;
export const BottomLeft = styled.div`
	max-width: 456px;

	& a {
		display: inline-block;
		color: #000000;
		transition: all 0.3s ease;
		&:hover {
			text-decoration: none;
		}
	}
`;

export const BottomRightText = styled.div`
	font-size: 14px;
	font-weight: 300;

	@media (max-width: 768px) {
		padding-left: 0;
	}
`;

export const InputWrapper = styled.div`
	display: flex;
	margin: 20px 0;
	@media (max-width: 425px) {
		margin: 16px 0 20px;
	}
	@media (max-width: 365px) {
		flex-direction: column;
		width: 100%;
		& > input,
		button {
			width: 100%;
			min-height: 48px;
		}
	}
`;
export const Button = styled.button`
	background-color: #4544f5;
	color: #fff;
	padding: 5px;
	width: 134px;
	font-size: 14px;
	font-weight: 300;
	transition: all 0.3s ease;
	cursor: pointer;

	&:hover {
		color: #000000;
		background: #ffffff;
		border: 1px solid #00000026;
		border-left: 1px solid transparent;
		background-color: #424bec1a;
	}
`;

export const BottomLeftText = styled.div`
	margin-top: 8px;
	font-size: 14px;
	font-weight: 300;
	line-height: 1.5;
	width: 374px;
	@media (max-width: 425px) {
		width: 100%;
		margin-top: 12px;
	}
`;
export const BottomPanel = styled.div`
	background: #000;
`;
export const BottomPanelInner = styled.div`
	display: flex;
	justify-content: flex-end;
	background: #000;
	color: #fff;
	font-size: 14px;
	font-weight: 300;
`;
export const BottomPanelList = styled.div`
	list-style: none;
	display: flex;
	padding: 10px;
	@media (max-width: 768px) {
		padding: 10px 0;
	}
`;
export const BottomPanelItem = styled(Link)`
	margin: 0 24px;
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	cursor: pointer;
	transition: all 0.3s ease;
	text-decoration: none;
	&:hover {
		color: rgb(66, 75, 236);
	}
	@media (max-width: 768px) {
		margin: 0 8px;
	}
	@media (max-width: 425px) {
		font-size: 12px;
		margin: 0 8px;
	}
	@media (max-width: 389px) {
		font-size: 12px;
		margin: 0 6px;
	}
	@media (max-width: 355px) {
		font-size: 10px;
	}
`;
export const BottomPanelItemText = styled.div`
	margin: 0 24px;
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	cursor: pointer;
	transition: all 0.3s ease;
	text-decoration: none;
	&:hover {
		color: rgb(66, 75, 236);
	}
	@media (max-width: 768px) {
		margin: 0 8px;
	}
	@media (max-width: 425px) {
		font-size: 12px;
		margin: 0 8px;
	}
	@media (max-width: 389px) {
		font-size: 12px;
		margin: 0 6px;
	}
`;

export const NswText = styled.p`
	text-transform: uppercase;
	font-size: 12px;
`;
export const Privacy = styled.div`
	font-size: 12px;
	font-weight: 300;
`;

export const SignUp = styled.div`
	width: 100%;
`;

export const TextHovered = styled.div`
	font-family: 'MuseoSans', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;

	color: #000000;
	transition: all 0.3s ease;
	cursor: pointer;
	&:hover {
		color: #424bec;
		text-decoration: none;
	}
`;

export const Text = styled.span`
	display: inline-block;
	margin: 5px 0;

	& > a {
		margin: 0;
		padding: 0 5px 0 0;
	}
`;
