import React from 'react';
import Logo from '../../images/Logo_blue.svg';
import NSWSvg from '../../images/nsw-gov.svg';
import { Content } from '../Content';
import {
	AboutUs,
	AboutUsText,
	BottomInner,
	BottomLeft,
	BottomLeftText,
	BottomPanel,
	BottomPanelInner,
	BottomPanelItem,
	BottomPanelList,
	BottomRight,
	BottomRightText,
	Button,
	Contacts,
	ContactsText,
	ContactsTextFourth,
	ContactsTextSecond,
	ContactsTextThird,
	FooterStyled,
	Img,
	Input,
	InputWrapper,
	Item,
	List,
	ListTitle,
	Media,
	Middle,
	MiddleWrapper,
	NswArea,
	NswText,
	Privacy,
	SignUp,
	Text,
	TextHovered,
	Top,
	TopWrapper,
} from './FooterStyles';
import { BsInstagram, BsLinkedin, BsTwitter } from 'react-icons/bs';
import { AiFillMediumSquare } from 'react-icons/ai';
import { FaFacebookSquare } from 'react-icons/fa';
import { Link } from 'gatsby';
import TypeFormComponent from 'components/common/TypeForm';

const Footer = () => {
	return (
		<FooterStyled>
			<TopWrapper>
				<Content>
					<Top>
						<AboutUs>
							<Link to='/'>
								<img src={Logo} alt='' width='187px' style={{ margin: '0px', cursor: 'pointer' }} />
							</Link>
							<AboutUsText>
								BookPhysio.com is Australia’s #1 physiotherapy booking site. We aim to help everyday
								Australian’s access reliable, evidence-based health information and suitable treatment
								options via our booking engine.
							</AboutUsText>
						</AboutUs>
						<Contacts>
							<Media>
								<a
									href='https://www.linkedin.com/company/bookphysio'
									target='_blank'
									rel='noopener noreferrer'
								>
									<BsLinkedin
										style={{
											width: '20px',
											height: '20px',
											margin: '0 6px',
										}}
									/>
								</a>
								<a
									href='https://www.facebook.com/BookPhysio1/'
									target='_blank'
									rel='noopener noreferrer'
								>
									<FaFacebookSquare
										style={{
											width: '22px',
											height: '22px',
											margin: '0 6px',
										}}
									/>
								</a>
								<a
									href='https://www.instagram.com/bookphysio/'
									target='_blank'
									rel='noopener noreferrer'
								>
									<BsInstagram
										style={{
											width: '20px',
											height: '20px',
											margin: '0 6px',
										}}
									/>
								</a>
								<a href='https://twitter.com/bookphysio_au' target='_blank' rel='noopener noreferrer'>
									<BsTwitter
										style={{
											width: '20px',
											height: '20px',
											margin: '0 6px',
										}}
									/>
								</a>
								<a href='https://medium.com/book-physio' target='_blank' rel='noopener noreferrer'>
									<AiFillMediumSquare
										style={{
											width: '24px',
											height: '24px',
											margin: '0 6px',
										}}
									/>
								</a>
							</Media>
							<ContactsText>Head office 2/11 York Street, Sydney NSW 2000</ContactsText>
							<ContactsTextSecond>Questions about our product or services?</ContactsTextSecond>
							<ContactsTextThird>Call us Monday - Friday 9am - 6pm AEST</ContactsTextThird>
							<ContactsTextFourth>
								<Text>
									<a href='tel:+610290686658'>(02) 9068 6658</a>
								</Text>
							</ContactsTextFourth>
						</Contacts>
					</Top>
				</Content>
			</TopWrapper>

			<MiddleWrapper>
				<Content>
					<Middle>
						<div>
							<ListTitle>COMPANY</ListTitle>
							<List>
								<Item>
									<Link to='/about'>
										<TextHovered>About</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/about/team'>
										<TextHovered>Team</TextHovered>
									</Link>
								</Item>
								<Item>FAQs</Item>
								<Item>
									<Link to='/contact-us'>
										<TextHovered>Contact us</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/careers'>
										<TextHovered>Careers</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/media'>
										<TextHovered>Media</TextHovered>
									</Link>
								</Item>
								<Item>Investors</Item>
								<Item>Blog</Item>
								<Item>
									<Link to='/terms-and-conditions'>
										<TextHovered>Terms</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/privacy-policy'>
										<TextHovered>Privacy Policy</TextHovered>
									</Link>
								</Item>
							</List>
						</div>
						<div>
							<ListTitle>FOR PATIENTS</ListTitle>
							<List>
								<Item>
									<Link to='/patient-resources'>
										<TextHovered>Patient resources</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/physiotherapy'>
										<TextHovered>Physiotherapy services</TextHovered>
									</Link>
								</Item>
								<Item>FAQs</Item>
								<Item>
									<Link to='/pain'>
										<TextHovered>Pain articles</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/injuries'>
										<TextHovered>Injury articles</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/conditions'>
										<TextHovered>Conditions</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/book-now'>
										<TextHovered>Book a Physio</TextHovered>
									</Link>
								</Item>
								{/* <Item>Back pain overview</Item> */}
								{/* <Item>Tennis elbow overview</Item> */}
								<Item>
									<Link to='/popular-clinics'>
										<TextHovered>Popular clinics</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/popular-locations'>
										<TextHovered>Popular locations</TextHovered>
									</Link>
								</Item>
							</List>
						</div>
						<div>
							<ListTitle>POPULAR SEARCHES</ListTitle>
							<List>
								<Item>
									<Link to='/sydney'>
										<TextHovered>Physio in Sydney</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/brisbane'>
										<TextHovered>Physio in Brisbane </TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/melbourne'>
										<TextHovered>Physio in Melbourne</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/adelaide'>
										<TextHovered>Physio in Adelaide</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/near-me'>
										<TextHovered>Physio near me</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/find'>
										<TextHovered>Find a physio</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/sports-physio'>
										<TextHovered>Sports physiotherapy</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/nsw/sydney-cbd-2000'>
										<TextHovered>Sydney CBD physio</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/qld/brisbane-cbd-4000'>
										<TextHovered>Brisbane CBD physio</TextHovered>
									</Link>
								</Item>
								<Item>
									<Link to='/vic/melbourne-cbd-3000'>
										<TextHovered>Melbourne CBD physio</TextHovered>
									</Link>
								</Item>
							</List>
						</div>
						<div>
							<ListTitle>FOR CLINICS</ListTitle>
							<List>
								<Item>
									<TypeFormComponent>
										<TextHovered>Clinic registration</TextHovered>
									</TypeFormComponent>
								</Item>
								<Item>Blog</Item>
							</List>
						</div>
					</Middle>
				</Content>
			</MiddleWrapper>
			<Content>
				<BottomInner>
					<BottomLeft>
						<Link to='/'>
							<img src={Logo} alt='' width='187px' style={{ margin: '0px', cursor: 'pointer' }} />
						</Link>
						<BottomLeftText>
							BookPhysio.com is a Local Physio Network Pty Ltd Company. All rights reserved. Our website
							services, content, and products are for informational purposes only. BookPhysio.com does not
							provide individual medical advice, diagnosis, or treatment.{' '}
							<Link to='/content-disclaimer'>
								<TextHovered>See additional information</TextHovered>
							</Link>
							.
						</BottomLeftText>
						<NswArea>
							<Img src={NSWSvg} alt='' width='60px' />
							<NswText>Proudly supported by the nsw government</NswText>
						</NswArea>
					</BottomLeft>
					<BottomRight>
						<BottomRightText>
							We’ve got your back, and whatever else hurts too™. Join our mail list for new and up to date
							health articles.
						</BottomRightText>
						<SignUp>
							<InputWrapper>
								<Input type='text' placeholder='Enter your email' />
								<Button>SIGN UP</Button>
							</InputWrapper>
							<Privacy>
								Your{' '}
								<Link to='/privacy-policy'>
									<TextHovered style={{ fontSize: '12px' }}>privacy</TextHovered>
								</Link>{' '}
								is important for us
							</Privacy>
						</SignUp>
					</BottomRight>
				</BottomInner>
			</Content>
			<BottomPanel>
				<Content>
					<BottomPanelInner>
						<BottomPanelList>
							<BottomPanelItem to='/nt'>NT</BottomPanelItem>
							<BottomPanelItem to='/wa'>WA</BottomPanelItem>
							<BottomPanelItem to='/act'>ACT</BottomPanelItem>
							<BottomPanelItem to='/tas'>TAS</BottomPanelItem>
							<BottomPanelItem to='/sa'>SA</BottomPanelItem>
							<BottomPanelItem to='/qld'>QLD</BottomPanelItem>
							<BottomPanelItem to='/nsw'>NSW</BottomPanelItem>
							<BottomPanelItem to='/vic'>VIC</BottomPanelItem>
							<BottomPanelItem to='/sitemap'>Site Map</BottomPanelItem>
						</BottomPanelList>
					</BottomPanelInner>
				</Content>
			</BottomPanel>
		</FooterStyled>
	);
};

export default Footer;
