import styled from 'styled-components';

export const Content = styled.div<{ isOpen?: boolean }>`
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 15px;

	& > div {
		& > p {
			font-weight: 300;
			font-size: 17px;
			margin: 25px 0;
			color: #333e48;
			line-height: 1.5;
			& a {
				color: ${({ theme }) => theme.palette.purp};
				margin: 0 2px;
				transition: all 0.3s ease;

				&:hover {
					color: ${({ theme }) => theme.palette.main};
					text-decoration: none;
				}
			}
		}
		& > h3 {
			font-size: 24px;
			font-weight: 600;
			line-height: 1.4;
			color: #152231;
			margin: 30px 0;
		}
	}

	& td,
	th {
		padding-right: 10px;
		padding-bottom: 10px;
	}
`;

export const ContentArticle = styled(Content)`
	padding: 0 15px 70px;

	@media (max-width: 767px) {
		padding: 0 15px 20px;
	}
`;
export const Content820 = styled(Content)`
	max-width: 850px;
`;
export const ContentArticle820 = styled(ContentArticle)`
	max-width: 850px;
`;
